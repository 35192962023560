// Asegúrate de que esta línea no esté comentada
const scrollContainer = document.querySelector('.scroll-container');

function scroll() {
    const firstChild = scrollContainer.firstElementChild;
    const scrollWidth = firstChild.offsetWidth;

    // Mueve el primer elemento al final
    scrollContainer.appendChild(firstChild.cloneNode(true));
    scrollContainer.scrollLeft += scrollWidth;

    // Elimina el primer elemento original después de un tiempo
    setTimeout(() => {
        scrollContainer.removeChild(scrollContainer.firstElementChild);
    }, 1000);
}

// Inicia el desplazamiento cada 300 ms (ajusta la velocidad según sea necesario)
setInterval(scroll, 1000);

document.addEventListener("DOMContentLoaded", function () {
    const scrollToFormLink = document.querySelector("#scrollToFormLink");

    // Evento para el enlace que desplaza hacia la sección "Contáctanos"
    scrollToFormLink.addEventListener("click", function (e) {
        e.preventDefault(); // Evita el comportamiento predeterminado del enlace
        const contactSection = document.getElementById("contactanos");
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' }); // Desplazamiento suave hacia la sección "Contáctanos"
        } else {
            console.log("La sección 'Contáctanos' no se encontró.");
        }
    });

    const menuToggle = document.getElementById("menuToggle");
    const menu = document.getElementById("menu");

    menuToggle.addEventListener("click", function (event) {
        event.stopPropagation(); // Evita que el clic en el botón cierre el menú
        menu.classList.toggle("active");
    });

    document.addEventListener("click", function (event) {
        if (!menu.contains(event.target) && !menuToggle.contains(event.target)) {
            menu.classList.remove("active");
        }
    });
});