document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("miFormulario");

  form.addEventListener("submit", function (event) {
    event.preventDefault(); // Previene el envío por defecto del formulario

    const formData = new FormData(form);
    const data = {
      nombre: formData.get("nombre"),
      correo: formData.get("correo")
    };

    // Log para verificar los datos que se están enviando
    console.log("Datos del formulario:", data);

    // Enviar los datos al backend usando fetch
    fetch("https://backendlandingpage-production.up.railway.app/submit-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      console.log("Estado de la respuesta:", response.status);
      return response.json();
    })
    .then(result => {
      console.log("Resultado del servidor:", result);
      if (result.success) {
        // Recargar la página después de un envío exitoso
        window.location.reload();
      }
      // Elimina o comenta las alertas
      // else {
      //   alert("Hubo un error al enviar el formulario.");
      // }
    })
    .catch(error => {
      console.error("Error en la solicitud:", error);
      // Elimina o comenta la alerta
      // alert("Hubo un error al enviar el formulario.");
    });
  });
});
